<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0"> <!-- Left Text-->
        <b-col lg="6" class="d-none d-lg-flex align-items-center p-0">
          <div class="h-100 w-100 d-lg-flex align-items-center justify-content-center">
            <b-img fluid :src="sideImg" alt="Login V2" class="h-100 w-100" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col lg="6" class="d-flex align-items-center auth-bg px-1 p-lg-2">
          <b-col sm="8" md="6" lg="9" class="px-xl-1 mx-auto">
            <b-row align-v="start">
              <b-col lg="12"> <b-img fluid :src="sideLogo" alt="Login V2" class="h-25 w-25" />
              </b-col>
              <b-col lg="12">
                <h3>
                  <small class="text-secondary">Artemisa App / Viveros CVC</small>
                </h3>
              </b-col>
            </b-row>
            <b-card-title title-tag="h3" class="font-weight-bold mb-1">
              Ingrese con su cuenta
            </b-card-title>
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <!-- email -->
                <b-form-group>
                  <label for="login-email" class="text-success">Correo</label>
                  <validation-provider #default="{ errors }" name="Correo" rules="required|email">
                    <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                      name="login-email" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password" class="text-success">Contraseña</label>
                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" name="Contraseña" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password"
                        placeholder="············" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>



                <b-row align-v="start" class="my-0">
                  <b-col lg="7" class="my-1">
                    <b-form-checkbox v-model="rememberMe" name="login-remenber" :value="true" :unchecked-value="false">
                      Recuerdame
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="5" class="my-1">
                    <b-link href="/forgot-password" target="_blank" style="color: #121c4d">
                      Olvido su contraseña
                    </b-link>
                  </b-col>
                </b-row>
                <!-- submit buttons -->
                <b-button type="submit" variant="primary" block @click="validationForm">
                  Ingresar
                </b-button>

                <b-row align-v="start" class="my-0">
                  <b-col lg="8" class="my-1">
                    <b-link href="/register" target="_blank" style="color: #121c4d">
                      Registrarme como usuario externo
                    </b-link>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      rememberMe: false,
      sideImg: require('@/assets/images/pages/fondo_login.jpg'),
      sideLogo: require('@/assets/images/logo/logo.jpg'),
      required,
      email,
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },

  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.loading = true;
          this.$http
            .post('/login', {
              email: this.userEmail,
              password: this.password,
              remember_me: this.rememberMe,
            })
            .then(response => {
              localStorage.setItem('current_user', JSON.stringify(response.data.data))
              window.location.href = '/'
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  text: error.response.data.message,
                  title: 'Error',
                  icon: 'ErrorIcon',
                  variant: 'danger',
                }

              });
            }).finally(
              () => {
                this.loading = false;
              }
            )

        }
      })
    },


  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
